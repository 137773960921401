import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AppLayout from '@/layouts/AppLayout'
import Vuesax from "vuesax";
import VueMeta from 'vue-meta'

Vue.use(Vuesax)
Vue.use(VueMeta)
Vue.component('AppLayout', AppLayout)

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount("#app");