<template>
  <app-layout>
    <transition mode="out-in">
      <router-view/>
    </transition>
  </app-layout>
</template>

<script>
export default {
  metaInfo: {
    titleTemplate: '%sQuiz',
  },
}
</script>

<style lang="scss">
@import "~vuesax/dist/vuesax.css";
@import "index.css";
@import "~@/assets/scss/typography.scss";

html, body {
  height: 100%;
}

.z-1 {
  z-index: 1;
}

.complex-card {
  box-shadow: inset 0 0 8px rgba(255, 255, 255, 0.05),
  0 8px 20px rgba(0, 0, 0, 0.25),
  0 8px 15px rgba(255, 255, 255, 0.15);
}

.content-view {
  width: 100%;
  @include px(1rem);
  @include mx(auto);
  max-width: 86rem;
}
</style>
