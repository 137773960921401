import VueRouter from "vue-router";
import Home from '../views/Home.vue'
import Question from '../views/Question.vue'
import Form from "../views/Form";

import Vue from "vue";
import VueMeta from 'vue-meta'

Vue.use(VueRouter);
Vue.use(VueMeta)


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return new Promise(resolve => {
        return originalPush
            .bind(this)(location)
            .then(route => {
                resolve(route);
            })
            .catch(error => {
                if (error.name === "NavigationDuplicated") {
                    // reload when the same route called
                    window.location.reload();
                }
            });
    });
};


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            layout: 'DefaultLayout',
            title: "Home"
        },
    },
    {
        path: '/question',
        name: 'Question',
        component: Question,
        meta: {
            layout: 'QuestionLayout',
            title: "Question"
        },
    },
    {
        path: '/form',
        name: 'Form',
        component: Form,
        meta: {
            layout: 'FormLayout',
            title: "Form"
        },
    },
]


export const router = new VueRouter({
    mode: "history",
    base: "/",
    routes
});

export default router