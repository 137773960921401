<template>
  <div class="flex justify-center xl:bg-gray-50 h-screen">
    <div class="max-w">
      <div v-if="!loading.isVisible" class="flex flex-row flex-wrap xl:h-screen	items-center relative">
        <div class="xl:w-6/12 w-full h-48 md:h-64 dash_img">
          <div class="h-full bg-cover bg-center"
               :style="{'background-image': `url(${data.image})`}"></div>
        </div>
        <div class="xl:absolute px-0 xl:px-10 xl:w-6/12 2xl:w-5/12 items-center z-20 right-20 2xl:right-60">

          <div class="px-10 py-10 xl:shadow-2xl xl:rounded-xl bg-white">

            <h3>{{ data.title }}</h3>
            <h4 class="pt-5 font-light text-gray-500">{{ data.description }}</h4>
            <div class="flex flex-col justify-center">
              <vs-button :color=data.color to="/question" class="my-5 p-2 xl:mx-0 shadow-xl">
                <h4>To answer</h4>
              </vs-button>
              <bonus :color="data.color"></bonus>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bonus from "@/components/Bonus";
import {get_quiz} from "@/mixin/request";

export default {
  name: "Home",

  created() {
    get_quiz().then(
        data => {
          this.data = data
          this.color = data.color
          document.title = data.title
          this.loading.close()
        }
    )
  },

  components: {Bonus},
  data: function () {
    return {
      data: {color: "#000", image: null, title: "", description: ""},
      loading: this.$vs.loading({
        background: '#fff',
        color: '#000'
      })
    }
  }
}
</script>

<style lang="scss">

@import "~@/assets/scss/home";

</style>
