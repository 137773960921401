<template>
  <div class="mt-7 mx-0">
    <!--    <h5>Bonus</h5>-->
    <!--    <p class="text-gray-400 mb-4">Пройдите опрос и увеличьте свой бонус</p>-->
    <div class="flex items-center flex-row justify-center" :style="bonusStyle">
      <div class="px-4 py-2 m-0 flex flex-row items-center rounded-xl shadow-xl bonus__item">
        <h4 class="text-black mr-5 ml-3 z-20">{{ val }} $</h4>
        <img src="~@/assets/images/bonus.svg" class="p-2 rounded-xl shadow-xl w-12 h-12 bonus_img z-20">
      </div>
    </div>
  </div>
</template>

<script>
import {ColorMixin} from "@/mixin/light";

export default {
  name: "Bonus",
  props: {color: {default: "#000000"}, val: {default: 0}},
  mixins: [ColorMixin]
}
</script>

<style lang="scss">
@import "~@/assets/scss/bonus";

</style>