<template>
  <div class="flex justify-center xl:h-screen items-center bg-gray-50">
    <div v-if="!loading.isVisible"
         class="max-w flex 2xl:flex-row flex-col rounded-xl shadow-xl bg-white px-10 py-5 justify-between">
      <div class="relative w-full">
        <div>
          <h2 class="py-5 w-full">
            {{ data.questions[step].question }}
          </h2>
          <div id="question" class="flex flex-col xl:flex-row flex-wrap mt-2 pb-10 xl:pb-0 items-center">
            <div class="pr-5 w-full xl:w-1/2  my-2" :key="key"
                 v-for="(item, key) in data.questions[step].answer">
              <div :key="key"
                   class="shadow-xl button_quiz rounded-xl p-4 border text-black w-full hover:bg-gray-50"
                   @click.prevent="handler_event">
                <h4 class="z-20">{{ item }}</h4>
              </div>
            </div>
          </div>
          <div class="bottom-0 l-0 2xl:absolute w-full xl:mt-20">
            <q-progress :color="data.color" ref="progress" :max="this.data.questions.length"></q-progress>
          </div>
        </div>
      </div>
      <div>
        <bonus :color="data.color" class="justify-center px-10" :val="get_bonus()"></bonus>
      </div>
    </div>
  </div>
</template>

<script>
import Bonus from "@/components/Bonus";
import QProgress from "@/components/QProgress";
import {get_quiz} from "@/mixin/request";

export default {
  name: "Question",
  components: {QProgress, Bonus},
  methods: {
    event(func, event) {
      document.querySelector("#question").style.opacity = 0;
      this.$refs.progress.add_step()
      setTimeout(
          function () {
            func(event)
            document.querySelector("#question").style.opacity = 1;
          }.bind(this),
          250
      );
    },

    get_bonus() {
      return ((this.data.bonus / this.data.questions.length) * this.step).toFixed(2)
    },

    next_step(event) {
      this.step += 1;
      if (this.step >= this.data.questions.length) {
        localStorage.setItem("answer", JSON.stringify(this.answer))
        this.$router.push('/form')
      } else {
        this.answer[this.data.questions[this.step].question] = event.target.innerText
      }
    },

    handler_event(event) {
      this.event(this.next_step, event)
    }
  },

  created() {
    get_quiz().then(
        data => {
          this.data = data
          this.loading.close()
        }
    ).catch(() => setTimeout(this.created, 2000))
  },


  data: function () {
    return {
      loading: this.$vs.loading({
        background: '#fff',
        color: '#000'
      }),
      data: {color: "#000", bonus: 0, questions: [{question: ""}],},
      step: 0,
      question: 0,
      answer: {}
    }
  }
}
</script>

<style scoped lang="scss">

@import "~@/assets/scss/bonus";
@import "~@/assets/scss/question";

</style>
