<template>
  <div class="flex justify-center bg-gray-50 h-screen">
    <div v-if="!loading.isVisible" class="max-w">
      <div class="flex flex-row flex-wrap xl:h-screen	items-center relative">
        <div class="xl:w-6/12 w-full h-48 md:h-64 dash_img">
          <div class="h-full bg-cover bg-center"
               :style="{'background-image': `url(${data.image})`}"></div>
        </div>
        <div class="xl:absolute px-0 2xl:w-5/12 xl:w-6/12 items-center z-20 2xl:right-48 right-10">
          <div class="px-8 py-10 shadow-2xl rounded-xl bg-white border">
            <div class="flex flex-col xl:grid xl:grid-cols-2 grid-cols-1 justify-center items-center">
              <div class="mb-5 pr-5 justify-center flex flex-col">
                <p class="whitespace-pre-wrap" v-text="data.text_form">
                </p>
              </div>
              <div class="pr-7">
                <div class="center content-inputs my-4">
                  <vs-input
                      :state="errors.name === undefined ? 'danger': null"
                      @click="errors.name = null"
                      label="Enter your name"
                      v-model="value.name"
                      placeholder="name"
                  />
                </div>
                <div class="center content-inputs mt-10">
                  <vs-input
                      :state="errors.phone === undefined ? 'danger': null"
                      @click="errors.phone = null"
                      label="enter your phone"
                      v-model="value.phone"
                      placeholder="phone number"
                  />
                </div>
                <div class="center content-inputs mt-10">
                  <vs-input
                      :state="errors.email === undefined ? 'danger': null"
                      @click="errors.email = null"
                      label="enter your email"
                      v-model="value.email"
                      placeholder="phone email"
                  />
                </div>
                <div class="flex flex-col">
                  <vs-button :color=data.color @click="send()" class="my-5 p-2 xl:mx-0 shadow-xl">
                    <h4>Get bonus</h4>
                  </vs-button>
                  <bonus :val="data.bonus" :color="data.color"></bonus>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bonus from "@/components/Bonus";
import {get_quiz, request} from "@/mixin/request";

export default {
  name: "Form",
  components: {Bonus},
  created() {
    let answer = localStorage.getItem("answer")
    if (!answer) {
      this.$router.push('/')
      return
    }
    get_quiz().then(
        data => {
          this.data = data
          document.title = data.title
          this.loading.close()
        }
    )
  },

  methods: {
    send: function () {
      request().post("/quiz/answer/", this.value).then(
          res => {
            if (res) {
              localStorage.clear()
              window.location.href = this.data.link_after
            }
          }
      ).catch(res => {
            if (res.response) {
              for (let key in res.response.data) {
                this.errors[key] = undefined
              }
            }
          }
      )
    }
  },

  data: function () {
    return {
      loading: this.$vs.loading({
        background: '#fff',
        color: '#000'
      }),
      data: {color: "#000", bonus: 0, image: null, text_form: "", link_after: "/"},
      value: {
        name: null, phone: null, email: null
      },
      errors: {
        name: null, phone: null, email: null
      }
    }
  }
}
</script>

<style lang="scss">

.vs-input {
  width: 100%;
}

</style>
