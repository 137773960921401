<template>
  <div class="w-full bg-gray-400 shadow-2xl rounded-xl" :style="bonusStyle">
    <div class="progress h-6 shadow-xl rounded-xl"
         :style="{width: `${present()}%`}">
    </div>
  </div>
</template>

<script>
import {ColorMixin} from "@/mixin/light";

export default {
  name: "QProgress",
  props: {"max": {default: 1}, "step": {default: 0}, color: {default: "#000000"}},
  mixins: [ColorMixin],
  methods: {
    present: function () {
      return this.current_step * 100 / this.max
    },
    add_step: function (count = 1) {
      this.current_step += count;
    },
    remove_step: function (count = 1) {
      this.current_step -= count;
    },
  },
  data: function () {
    return {
      current_step: this.step,
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/qprogress";
</style>