import axios from "axios";


export function request() {
    let baseUrl = process.env.NODE_ENV === "production" ? "/api/v1" : "http://0.0.0.0:8017/api/v1"
    return axios.create({
        baseURL: baseUrl,
        headers: {
            accept: "application/json",
            "Content-Type": "application/json"
        }
    });
}


export function get_quiz() {
    return request().get("/quiz/").then(
        response => {
            localStorage.setItem("quiz", JSON.stringify(response.data))
            return response.data
        }
    ).catch(() => setTimeout(get_quiz, 2000))
}

export function get_quiz_data() {
    let quiz_data = localStorage.getItem("quiz")
    if (quiz_data) {
        return JSON.parse(quiz_data)
    } else {
        return null
    }

}